































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { UserForgetProjectService } from "@/core/services";
import { SectorIconCpt } from "@/core/components";

import AppVue from "@/AppVue.vue";
import {
  ProjectSearchResultModel,
  ProjectSearchResultCompanyModel,
} from "@/core/models";
import { ProjectLabeledSectionCpt } from "../../project";
import LabeledCompanyCpt from "./LabeledCompanyCpt.vue";
import { roles as Roles, roleFlag } from "@/core/constants";
import TrackingNotesCpt from "@/modules/tracking/components/TrackingNotesCpt.vue";
import BlockIcon from "vue-material-design-icons/BlockHelper.vue";

@Component({
  components: {
    LabeledCompanyCpt: () => Promise.resolve(LabeledCompanyCpt),
    ProjectLabeledSectionCpt: () => Promise.resolve(ProjectLabeledSectionCpt),
    SectorIconCpt: () => Promise.resolve(SectorIconCpt),
    TrackingNotesCpt: () => Promise.resolve(TrackingNotesCpt),
    BlockIcon: () => Promise.resolve(BlockIcon),
  },
})
export default class SearchResultDetailsCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) model: ProjectSearchResultModel;
  @Prop({ default: null }) companyId: number;
  roles = { icons: roleFlag, ...Roles };
  isForgotten = false;

  @Watch("model.projectId")
  async reloadForgottenProjectOnProjectChange() {
    await this.init();
  }

  async exists() {
    return await UserForgetProjectService.exists(Number(this.model.projectId));
  }

  async forgetProjectSwitch() {
    if (this.isForgotten) {
      await UserForgetProjectService.DeleteUserForgetProject(
        Number(this.model.projectId),
      );
      this.isForgotten = false;
    } else {
      try {
        await UserForgetProjectService.createUserForgetProject(
          Number(this.model.projectId),
        );
        this.notify(
          "Project forgotten",
          this.forgetProjectMessage,
          "success",
          true,
        );
        this.isForgotten = true;
        this.$emit("projectForgotten", { projectId: this.model.projectId });
      } catch (ex) {
        if (ex == "Error: Request failed with status code 400") {
          this.notify(
            "Limit reached",
            this.maxForgottenProjectsReachedMessage,
            "warning",
            true,
          );
        } else {
          this.notify("Error", ex, "error", true);
        }
      }
    }
  }

  get Architects() {
    return this.filterByGroup(this.model.companies, this.roles.Architect);
  }

  get Promoters() {
    return this.filterByGroup(this.model.companies, this.roles.Promoter);
  }

  get Contractors() {
    return this.filterByGroup(this.model.companies, this.roles.Contractor);
  }

  get ForgetText() {
    return "Forgetting a project will remove it from all search results and alerts. You can review forgotten projects from your account page.";
  }

  filterByGroup(
    companyList: ProjectSearchResultCompanyModel[],
    group: string,
  ): ProjectSearchResultCompanyModel[] {
    return companyList.filter((x) => x.roleName === group);
  }

  handleClick() {
    this.$emit("close");
  }
  async created() {
    await this.init();
  }
  async init() {
    this.isForgotten = await this.exists();
  }
  async mounted() {}
}

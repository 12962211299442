















import AppVue from "@/AppVue.vue";
import moment from "moment";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ProjectTableNotificationCpt extends AppVue {
  @Prop({ default: 0 }) projectId: number;
  @Prop({ default: new Date() }) dateCreated: Date;
  @Prop({ default: new Date() }) dateUpdated: Date;

  updatedDays = 7;

  get isNew() {
    return moment().diff(this.dateCreated, "days") < this.updatedDays;
  }

  get isUpdated() {
    if (this.isNew) {
      return false;
    } else {
      return moment().diff(this.dateUpdated, "days") < this.updatedDays;
    }
  }
}






































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import {
  IUIFilters,
  SearchService,
  TurnstoneLookupsModel,
  IGeoRange,
  IMaxMinValue,
  ProfileModel,
} from "@/core/services";
import {} from "@/core/components";
import { AccountService } from "@/core/services";

import AppVue from "@/AppVue.vue";
import { DateRangeFilter, RoleContactModel } from "@/core/models";
import {
  FilterValueCpt,
  FilterCategoryCpt,
  FilterGenericCompareCpt,
  FilterStageCpt,
  FilterDatesCpt,
  FilterLocationCpt,
  FilterGeoRangeCpt,
  FilterMaterialsCpt,
  FilterTrackedUntrackedCpt,
} from ".";
import AdditionalFiltersCpt from "./AdditionalFiltersCpt.vue";
import { MapEvents } from "@/modules/map/MapView.vue";
import FilterRoleCpt from "./FilterRoleCpt.vue";
import FilterPolylineCpt from "./FilterPolylineCpt.vue";
import { LatLng } from "leaflet";
import FilterAppealStatusCpt from "./FilterAppealStatusCpt.vue";
export const orderByFields = [
  {
    value: "DateUpdated desc",
    label: "Date updated",
    operator: "desc",
    tooltip: "",
  },
  {
    value: "DateUpdated asc",
    label: "Date updated",
    operator: "asc",
    tooltip: "",
  },
  {
    value: "search.score() desc",
    label: "Relevance",
    operator: "",
    tooltip: "Relevance is a mix of date updated and keywords that best match your search",
  },
  {
    value: "ProjectStartDate desc",
    label: "Start date",
    operator: "desc",
    tooltip: "",
  },
  {
    value: "ProjectStartDate asc",
    label: "Start date",
    operator: "asc",
    tooltip: "",
  },
  {
    value: "Value desc",
    label: "Project value",
    operator: "desc",
    tooltip: "",
  },
  { value: "Value asc", label: "Project value", operator: "asc", tooltip: "" },
];
export const events = {
  FilterUpdated: "filterUpdated",
  OrderByUpdated: "orderByUpdated",
  SetDefaultFilters: "filterSet",
  ClearFilters: "clearFilters",
  ToggleMap: "toggleMap",
};
class FilterDefinition {
  key: string;
  label: string;
  visible: boolean;
  canBeHidden: boolean;
}
class VisibleFiltersModel {
  filterGeoRange: FilterDefinition;
  filterValue: FilterDefinition;
  filterStage: FilterDefinition;
  filterCategory: FilterDefinition;
  filterLocation: FilterDefinition;
  filterAppealStatus: FilterDefinition;
  filterDateUpdated: FilterDefinition;
  filterTenderDeadlineDate: FilterDefinition;
  filterFinishDate: FilterDefinition;
  filterDecisionDate: FilterDefinition;
  filterProjectStartDate: FilterDefinition;
  filterApplicationDate: FilterDefinition;
  filterContractStage: FilterDefinition;
  trackingFilter: FilterDefinition;
  filterApplicationType: FilterDefinition;
}
@Component({
  components: {
    AdditionalFiltersCpt: () => Promise.resolve(AdditionalFiltersCpt),
    FilterValueCpt: () => Promise.resolve(FilterValueCpt),
    FilterStageCpt: () => Promise.resolve(FilterStageCpt),
    FilterDatesCpt: () => Promise.resolve(FilterDatesCpt),
    FilterGenericCompareCpt: () => Promise.resolve(FilterGenericCompareCpt),
    FilterCategoryCpt: () => Promise.resolve(FilterCategoryCpt),
    FilterLocationCpt: () => Promise.resolve(FilterLocationCpt),
    FilterMaterialsCpt: () => Promise.resolve(FilterMaterialsCpt),
    FilterGeoRangeCpt: () => Promise.resolve(FilterGeoRangeCpt),
    FilterPolylineCpt: () => Promise.resolve(FilterPolylineCpt),
    FilterTrackedUntrackedCpt: () => Promise.resolve(FilterTrackedUntrackedCpt),
  },
})
export default class SearchFilterBarCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) mobileDialog: boolean;
  @Prop({ default: () => null }) initFilters: IUIFilters;
  lookups: TurnstoneLookupsModel = new TurnstoneLookupsModel();
  profile = new ProfileModel();
  // Set the current view Id: MapView(1), ListView(2)

  filters: IUIFilters = SearchService.defaultFilter();
  showMapOnMobile = false;
  orderByFieldValue = "";
  orderByFields = orderByFields;
  orderByFieldSelection: string = orderByFields[0].label;
  orderByDirection: string = orderByFields[0].operator;
  // this could be converted to a feature flag, but right now it is just enabled.
  // FR 45900
  isSelfBuildEnabled = true;
  featureMapOnMobileEnabled = false;
  visibleFilters: VisibleFiltersModel = {
    filterAppealStatus: {
      key: "appealStatusFilter",
      canBeHidden: true,
      visible: true,
      label: "Appeal Status",
    },
    filterApplicationType: {
      key: "applicationTypeFilter",
      canBeHidden: true,
      visible: true,
      label: "Application Type",
    },
    trackingFilter: {
      key: "trackingfilter",
      canBeHidden: true,
      visible: true,
      label: "Tracked",
    },
    filterGeoRange: {
      key: "filterGeoRange",
      canBeHidden: false,
      visible: true,
      label: "Geolocation",
    },
    filterValue: {
      key: "filterValue",
      canBeHidden: false,
      visible: true,
      label: "Value",
    },
    filterStage: {
      key: "filterStage",
      canBeHidden: false,
      visible: true,
      label: "Stage",
    },
    filterContractStage: {
      key: "filterContractStage",
      canBeHidden: false,
      visible: true,
      label: "ContractStage",
    },
    filterCategory: {
      key: "filterCategory",
      canBeHidden: false,
      visible: true,
      label: "Category",
    },
    filterLocation: {
      key: "filterLocation",
      canBeHidden: false,
      visible: true,
      label: "Location",
    },
    filterDateUpdated: {
      key: "filterDateUpdated",
      canBeHidden: false,
      visible: true,
      label: "Date Updated",
    },
    filterTenderDeadlineDate: {
      key: "filterTenderDeadlineDate",
      canBeHidden: true,
      visible: false,
      label: "Tender Deadline Date",
    },
    filterFinishDate: {
      key: "filterFinishDate",
      canBeHidden: true,
      visible: false,
      label: "Finish Date",
    },
    filterDecisionDate: {
      key: "filterDecisionDate",
      canBeHidden: true,
      visible: false,
      label: "Decision Date",
    },
    filterProjectStartDate: {
      key: "filterProjectStartDate",
      canBeHidden: true,
      visible: false,
      label: "Start Date",
    },
    filterApplicationDate: {
      key: "filterApplicationDate",
      canBeHidden: true,
      visible: false,
      label: "Application Date",
    },
  };
  get filtersMobileClass() {
    return this.$isMobile() ? "show-mobile" : "";
  }

  get IsSelfBuild() {
    const t = AccountService.getSubscriptionType();
    return t.IsSelfBuild === "True";
  }

  get IsMajorProjects() {
    const t = AccountService.getSubscriptionType();
    return t.IsMajorProjects === "True";
  }

  orderByFieldHandle(value: string) {
    this.orderByFieldValue = `${value}`;
    const v = this.orderByFields.filter((x) => value.indexOf(x.value) > -1)[0];
    if (!v) {
      this.orderByFieldSelection = orderByFields[0].label;
      this.orderByDirection = orderByFields[0].operator;
    } else {
      this.orderByFieldSelection = v.label;
      this.orderByDirection = v.operator;
    }

    this.$eventHub.$emit(events.OrderByUpdated, [this.orderByFieldValue]);
  }

  get isTableView() {
    return this.$route.path.indexOf("/t/") >= 0;
  }

  get currentViewId() {
    return this.isTableView ? 2 : 1;
  }

  get isDefaultView() {
    return this.profile.defaultView === this.currentViewId;
  }

  async setDefaultView() {
    const defaultViewId = (await AccountService.setDefaultView(this.profile.userProfileId, this.currentViewId)).data;
    this.profile.defaultView = defaultViewId;
    this.$eventHub.$emit("default_set", defaultViewId);
  }

  addFilter(filter: string) {
    const filterToAdd: any = this.visibleFilters[filter as keyof {}];
    filterToAdd.visible = true;
  }
  showMap() {
    const params: any = {
      projectLoaded: "100",
      clearCache: "0",
      reload: "false",
    };
    if (!!this.$route.params.filterId) {
      params.keepFilter = "1";
      params.filterId = this.$route.params?.filterId?.toString();
    }
    this.$router
      .push({
        name: "SearchProjects",
        params,
      })
      .catch(() => {});
  }
  showTable() {
    const params: any = {
      projectLoaded: "100",
      clearCache: "0",
      reload: "false",
    };
    if (!!this.$route.params.filterId) {
      params.keepFilter = "1";
      params.filterId = this.$route.params?.filterId?.toString();
    }
    this.$router
      .push({
        name: "TableSearchProjects",
        params,
      })
      .catch(() => {});
  }

  toggleMap() {
    this.showMapOnMobile = !this.showMapOnMobile;
    this.$eventHub.$emit(events.ToggleMap, this.showMapOnMobile);
    setTimeout(() => {
      this.$eventHub.$emit(MapEvents.Refresh);
    }, 500);
  }
  clearFilters() {
    this.filters = SearchService.defaultFilter();
    if (this.IsSelfBuild && this.IsMajorProjects) {
      this.filters.isSelfBuild = "LargeScaleOnly";
    } else if (this.IsSelfBuild && !this.IsMajorProjects) {
      this.filters.isSelfBuild = "SelfBuildOnly";
    }
    this.$eventHub.$emit(events.ClearFilters, this.filters);
    this.$eventHub.$emit(MapEvents.ClearDrawLayer);
  }
  additionalFiltersUpdated(filters: IUIFilters) {
    this.filters = filters;
    this.filtersUpdated();
  }
  filtersUpdated() {
    this.$eventHub.$emit(events.FilterUpdated, this.filters);
    this.$emit("FilterUpdated", this.filters);
  }
  setDefaultFilterVisibility(filters: IUIFilters) {
    this.filters = filters;
    if (this.filters.trackingFilter) {
      this.visibleFilters.trackingFilter.visible = true;
    }
    // now update the visible filters
    if (
      this.filters.tenderDeadlineDate.startDate ||
      this.filters.tenderDeadlineDate.endDate ||
      this.filters.tenderDeadlineDate.value
    ) {
      this.visibleFilters.filterTenderDeadlineDate.visible = true;
    }
    if (this.filters.finishDate.startDate || this.filters.finishDate.endDate || this.filters.finishDate.value) {
      this.visibleFilters.filterFinishDate.visible = true;
    }

    if (this.filters.decisionDate.startDate || this.filters.decisionDate.endDate || this.filters.decisionDate.value) {
      this.visibleFilters.filterDecisionDate.visible = true;
    }
    if (
      this.filters.projectStartDate.startDate ||
      this.filters.projectStartDate.endDate ||
      this.filters.projectStartDate.value
    ) {
      this.visibleFilters.filterProjectStartDate.visible = true;
    }
    if (
      this.filters.applicationDate.startDate ||
      this.filters.applicationDate.endDate ||
      this.filters.applicationDate.value
    ) {
      this.visibleFilters.filterApplicationDate.visible = true;
    }
  }
  valueUpdated(min: string, max: string) {
    this.filters.value.min = min;
    this.filters.value.max = max;
    this.filtersUpdated();
  }
  polylineUpdated(data: LatLng[]) {
    this.filters.polyline = data.map((x) => [x.lat, x.lng]) as number[][];
    this.filters.geoRange = {} as IGeoRange;
    this.filtersUpdated();
  }
  geoRangeUpdated(geoRange: IGeoRange) {
    this.filters.geoRange = geoRange;
    this.filters.polyline = [];
    this.filtersUpdated();
  }
  categoriesUpdated(selectedCategories: string[]) {
    this.filters.categories = selectedCategories;
    this.filtersUpdated();
  }
  projectTypesUpdated(selectedItems: string[]) {
    this.filters.projectTypes = selectedItems;
    this.filtersUpdated();
  }
  countiesUpdated(selectedItems: string[]) {
    this.filters.counties = selectedItems;
    this.filtersUpdated();
  }
  materialsUpdated(selectedItems: string[]) {
    this.filters.materials = selectedItems;
    this.filtersUpdated();
  }
  appealStatusUpdated(selectedStatusList: number[]) {
    this.filters.appealStatusList = selectedStatusList;
    this.filtersUpdated();
  }
  stagesUpdated(selectedStages: string[]) {
    this.filters.stage = selectedStages;
    this.filtersUpdated();
  }
  applicationTypesUpdated(selectedApplicationTypes: string[]) {
    this.filters.applicationType = selectedApplicationTypes;
    this.filtersUpdated();
  }
  changeDateUpdate(selectedDates: DateRangeFilter) {
    this.filters.dateUpdated = selectedDates;
    this.filtersUpdated();
  }
  trackingFilterUpdate(trackingFilter: string) {
    this.filters.trackingFilter = trackingFilter;
    this.$route.query.trackingFilter = trackingFilter;
    this.filtersUpdated();
  }
  async created() {
    // we clear the filters when we load the bar.
    // this assigns a default value to all filters
    // and computes user defaults
    // (self build + large projects defaults to large projects)
    // this happens only on a new page load
    this.clearFilters();
    // now we attach the event listener to set the default filters
    // for example from a saved search
    this.$eventHub.$on(events.SetDefaultFilters, this.setDefaultFilterVisibility);
    // load the lookups
    this.lookups = await SearchService.getLookups();

    this.profile = await AccountService.getProfile();
  }
  destroyed() {
    this.$eventHub.$off(events.SetDefaultFilters, this.setDefaultFilterVisibility);
  }

  async mounted() {
    if (!!this.initFilters) {
      this.setDefaultFilterVisibility(this.initFilters);
    } else {
    }
  }
}

















import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class ResultsLimitedCpt extends AppVue {
  mounted() {}
  async created() {}
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-search-details"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"icon"},[_c('SectorIconCpt',{attrs:{"categoryName":_vm.model.categories[0]}})],1),_c('div',{staticClass:"main"},[(_vm.companyId)?_c('router-link',{attrs:{"title":"Open project page","to":{
          name: 'ProjectInCompany',
          params: { projectId: _vm.model.projectId, companyId: _vm.companyId },
        }}},[_c('h2',[_vm._v(_vm._s(_vm.model.title))])]):_vm._e(),(!_vm.companyId)?_c('router-link',{attrs:{"title":"Open project page","to":{
          name: 'Project',
          params: { projectId: _vm.model.projectId },
        }}},[_c('h2',[_vm._v(_vm._s(_vm.model.title))])]):_vm._e(),_c('div',{staticClass:"actions"},[_c('el-button',{attrs:{"type":"warning","size":"small","circle":"","plain":"","icon":"el-icon-d-arrow-left"},on:{"click":function($event){return _vm.handleClick()}}})],1)],1)]),_c('div',{staticClass:"main"},[_c('ProjectLabeledSectionCpt',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"ProjectId","icon":"cis-icon-pin","value":_vm.model.projectId}}),_c('LabeledCompanyCpt',{attrs:{"icon":_vm.roles.icons.Promoter,"label":"Promoter / Client","companies":_vm.Promoters}}),_c('LabeledCompanyCpt',{attrs:{"icon":_vm.roles.icons.Architect,"label":_vm.roles.Architect,"companies":_vm.Architects}}),_c('LabeledCompanyCpt',{attrs:{"icon":_vm.roles.icons.Contractor,"label":_vm.roles.Contractor,"companies":_vm.Contractors}}),(
        _vm.model.projectTypes.includes('Public Sector Tender') ||
          _vm.model.stage === 'Tender'
      )?_c('ProjectLabeledSectionCpt',{attrs:{"label":"Tender deadline date","icon":"cis-icon-calendar","type":"date","value":_vm.model.tenderDeadline}}):_vm._e(),(false)?_c('ProjectLabeledSectionCpt',{attrs:{"label":"Application date","icon":"cis-icon-calendar","type":"date","value":_vm.model.applicationDate}}):_vm._e(),(false)?_c('ProjectLabeledSectionCpt',{attrs:{"label":"Decision date","icon":"cis-icon-calendar","type":"date","value":_vm.model.decisionDate}}):_vm._e(),(false)?_c('ProjectLabeledSectionCpt',{attrs:{"label":"Start date","icon":"cis-icon-calendar","type":"date","value":_vm.model.projectStartDate}}):_vm._e(),_c('ProjectLabeledSectionCpt',{attrs:{"label":"Address","icon":"cis-icon-pin","value":_vm.model.address}}),_c('ProjectLabeledSectionCpt',{attrs:{"label":"Description","icon":"cis-icon-description","type":"longtext","value":_vm._f("b$")(_vm.model.description,'em')}})],1),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"tracking"},[_c('TrackingNotesCpt',{attrs:{"projectId":_vm.model.projectId,"onlyUsers":true,"status":_vm.model.isWatched}})],1),_c('div',{staticClass:"primary"},[_c('el-button',{attrs:{"round":"","type":"info","size":"medium","plain":""},on:{"click":function($event){return _vm.forgetProjectSwitch()}}},[_c('BlockIcon',{attrs:{"size":12}}),_vm._v(" "),(_vm.isForgotten)?_c('span',[_vm._v("Remember Project")]):_c('span',[_vm._v("Forget Project")])],1),_vm._v(" "),(_vm.companyId)?_c('router-link',{attrs:{"to":{
          name: 'ProjectInCompany',
          params: { projectId: _vm.model.projectId, companyId: _vm.companyId },
        }}},[_c('el-button',{attrs:{"round":"","size":"medium","type":"warning"}},[_vm._v(" Open project ")])],1):_vm._e(),(!_vm.companyId)?_c('router-link',{attrs:{"to":{
          name: 'Project',
          params: { projectId: _vm.model.projectId },
        }}},[_c('el-button',{attrs:{"round":"","size":"medium","type":"warning"}},[_vm._v(" Open project ")])],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }

















import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class ResultsBlankSlateCpt extends AppVue {
  mounted() {}
  async created() {}
}

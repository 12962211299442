






























































































import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { DateRangeFilter, FreeTextSearchType, SearchModel } from "@/core/models";
import {
  FilterValueCpt,
  FilterCategoryCpt,
  FilterGenericCompareCpt,
  FilterStageCpt,
  FilterDatesCpt,
  FilterSelfBuildCpt,
  FilterRoleCpt,
  FilterLocationCpt,
  FilterGeoRangeCpt,
} from "@/modules/search/components";
import { AccountService, IUICompanyFilters, IUIFilters, SearchService, TurnstoneLookupsModel } from "@/core/services";
import FilterPolylineCpt from "./FilterPolylineCpt.vue";
import { LatLng } from "leaflet";

@Component({
  components: {
    FilterRoleCpt: () => Promise.resolve(FilterRoleCpt),
    FilterValueCpt: () => Promise.resolve(FilterValueCpt),
    FilterStageCpt: () => Promise.resolve(FilterStageCpt),
    FilterDatesCpt: () => Promise.resolve(FilterDatesCpt),
    FilterGenericCompareCpt: () => Promise.resolve(FilterGenericCompareCpt),
    FilterCategoryCpt: () => Promise.resolve(FilterCategoryCpt),
    FilterLocationCpt: () => Promise.resolve(FilterLocationCpt),
    FilterSelfBuildCpt: () => Promise.resolve(FilterSelfBuildCpt),
    FilterGeoRangeCpt: () => Promise.resolve(FilterGeoRangeCpt),
    FilterPolylineCpt: () => Promise.resolve(FilterPolylineCpt),
  },
})
export default class SearchFilterSummaryCpt extends AppVue {
  @Prop() model: SearchModel;
  @Prop({ default: false }) floatRight: boolean;
  @Prop({ default: "Project" }) type: string;
  filters: IUIFilters = SearchService.defaultFilter();
  companyFilters: IUICompanyFilters = {
    polyline: [],
    counties: Array<string>(),
    roles: Array<string>(),
    geoRange: {
      center: [],
      range: 0,
    },
    dateUpdated: new DateRangeFilter(),
    query: "",
    queryType: FreeTextSearchType.Any,
  };
  lookups: TurnstoneLookupsModel = new TurnstoneLookupsModel();
  isSelfBuildEnabled = true;

  get roleTreeData() {
    if (!this.lookups) {
      return [];
    }
    if (!this.lookups.roleList) {
      return [];
    }
    return this.lookups.roleList.map((role) => {
      return {
        id: role.roleID,
        label: role.name,
        children: role.childRoles
          ? role.childRoles.map((x: any) => {
              return {
                id: x.roleID,
                label: x.name,
              };
            })
          : null,
      };
    });
  }
  get polyLineCompany() {
    return this.companyFilters.polyline.map((x) => new LatLng(x[1], x[0]));
  }
  get polyLineProject() {
    return this.filters.polyline.map((x) => new LatLng(x[1], x[0]));
  }

  get IsSelfBuild() {
    const t = AccountService.getSubscriptionType();
    return t.IsSelfBuild === "True";
  }

  get IsMajorProjects() {
    const t = AccountService.getSubscriptionType();
    return t.IsMajorProjects === "True";
  }

  get float() {
    return this.floatRight ? "right" : "";
  }

  async created() {
    if (this.type === "Company") {
      this.companyFilters = SearchService.convertCompanySearchModelToFilters(this.model);
    } else {
      this.filters = SearchService.convertProjectSearchModelToFilters(this.model);
    }
    this.lookups = await SearchService.getLookups();
  }

  showDateFilter(filter: DateRangeFilter) {
    return filter.startDate || filter.endDate || filter.noOfdays || filter.value;
  }
}

export * from "./components";
export { default as CompanySearchResultsView } from "./CompanySearchResultsView.vue";
export { default as CompanySearchResultTableView } from "./CompanySearchResultTableView.vue";
export { default as ProjectSearchResultTableView } from "./ProjectSearchResultTableView.vue";
import { fromPairs } from "lodash";
import { defineLocale } from "moment";
import ProjectSearchResults from "./ProjectSearchResultsView.vue";
export {
  ProjectSearchResults,
};

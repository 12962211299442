




















































































import moment from "moment";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ProjectService } from "@/core/services";
import {
  SectorIconCpt,
  WatchedButton,
  ForgetProjectButtonCpt,
} from "@/core/components";
import AppVue from "@/AppVue.vue";
import {
  SearchResultContainer,
  ProjectModel,
  ProjectSearchResultModel,
} from "@/core/models";
import { WatcherService } from "@/core/services";

@Component({
  components: {
    SectorIconCpt,
    WatchedButton,
    ForgetProjectButtonCpt,
  },
})
export default class SearchResultItemCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: new SearchResultContainer() }) model: SearchResultContainer<
    ProjectSearchResultModel
  >;
  @Prop({ default: false }) isLinkedProject: boolean;
  @Prop({ default: false }) selected: boolean;
  @Prop({ default: false }) isWatched: boolean;
  @Prop({ default: false }) debug: boolean;
  @Prop({ default: "" }) searchId: string;
  @Prop({ default: 0 }) rank: number;

  hover = false;
  updatedDays = 7;
  @Watch("hover")
  onHover(newValue: boolean) {
    // this.$emit("hover", { overStatus: newValue, model: this.model });
  }

  get isLoading() {
    return this.loading;
  }

  get isNew() {
    return (
      moment().diff(this.model.document.dateCreated, "days") < this.updatedDays
    );
    return true; // model.document.dateCreated > mome
  }

  get isOpenTender() {
    // tenderDeadline < 3 months in future
    // stage "Tender"
    const d = moment
      .utc(this.model.document.tenderDeadline)
      .diff(moment().startOf("day"), "months", true);
    return this.model.document.stage === "Tender" && d < 3 && d > 0;
  }

  get isUpdated() {
    if (this.isNew) {
      return false;
    } else {
      return (
        moment().diff(this.model.document.dateUpdated, "days") <
        this.updatedDays
      );
    }
  }
  get highlights() {
    if (!!this.model["@search.highlights"]) {
      const o = this.model["@search.highlights"] as any;
      if (!!o.Description) {
        return o.Description as string[];
      }
    }
    return [];
  }
  async toggleWatchList(
    model: SearchResultContainer<ProjectSearchResultModel>,
  ) {
    const projectId = Number(model.document.projectId);
    if (this.isWatched) {
      await WatcherService.unwatchProject(projectId);
      this.$emit("unwatchedProject", model);
      this.$notify({
        title: "Tracking",
        message: `No longer tracking ${model.document.title}`,
        type: "success",
      });
    } else {
      await WatcherService.watchProject(model.document);
      this.$emit("watchedProject", model);
      this.$notify({
        title: "Tracking",
        message: `Now tracking ${model.document.title}`,
        type: "success",
      });
    }
  }

  projectForgotten() {
    this.$emit("projectForgotten", {
      projectId: this.model.document.projectId,
    });
  }

  selectItem(item: SearchResultContainer<ProjectSearchResultModel>) {
    if (this.isLinkedProject) {
      if (!!this.$route.params.companyId) {
        this.$router.push({
          name: "ProjectInCompany",
          params: {
            projectId: item.document.projectId,
            companyId: this.$route.params.companyId,
          },
        });
        return;
      }
      this.$router
        .push({
          name: "Project",
          params: {
            projectId: item.document.projectId,
          },
        })
        .catch(() => {});
    } else {
      this.trackEvent("PeekProject", {
        category: "Search Results",
        label: "PeekProject",
        value: item.document.projectId,
      });
      this.trackEvent("Click", {
        SearchServiceName: "Project search",
        SearchId: this.searchId,
        ClickedDocId: item.document.projectId,
        Rank: this.rank,
      });
      ProjectService.peek(item.document.projectId);
      this.$emit("selectItem", item);
    }
  }

  created() {
    this.$eventHub.$on("MarkerClicked", this.markerClick);
    this.$eventHub.$on("ScrollToResult", this.scrollToResult);
  }
  destroyed() {
    this.$eventHub.$off("MarkerClicked", this.markerClick);
    this.$eventHub.$on("ScrollToResult", this.scrollToResult);
  }
  scrollToResult(focusElement: any) {
    const pid = focusElement.resultId;
    if (this.model.document.projectId === pid) {
      this.$nextTick(() => {
        this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
      });
    }
  }
  markerClick(item: any) {
    const pid = item.data.projectId;
    if (this.model.document.projectId === pid) {
      this.$nextTick(() => {
        this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
      });
      this.selectItem(this.model);
    }
  }

  async mounted() {}
}

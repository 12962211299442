import {
  CompanySearchResultsView, ProjectSearchResults,
  SearchFilterBarCpt, CompanySearchResultTableView,
  ProjectSearchResultTableView,
} from ".";
import { HeaderCpt } from "@/layouts";
import { CompanySearchFilterBarCpt } from "./components";
import router from "@/router";
const mapView = import(/* webpackChunkName: "PackMapView" */ "../map/MapView.vue");
export const searchRoutes = [
  {
    path: "/search",
    redirect: "/search/100",
  },
  {
    path: "/search/:projectsLoaded/:filterId?",
    name: "SearchProjects",
    components: {
      default: ProjectSearchResults,
      map: () => mapView,
      filters: SearchFilterBarCpt,
      header: HeaderCpt,
    },
    meta: {
      layout: "map",
      breadCrumb: true,
    },
    props: {
      header: () => ({ searchType: "projectSearch" }),
    },
  },
  {
    path: "/t/search",
    redirect: "/t/search/100",
  },
  {
    path: "/t/search/:projectsLoaded/:filterId?",
    name: "TableSearchProjects",
    components: {
      default: ProjectSearchResultTableView,
      filters: SearchFilterBarCpt,
      header: HeaderCpt,
    },
    meta: {
      layout: "table",
      breadCrumb: true,
    },
    props: {
      header: () => ({ searchType: "projectSearch" }),
    },
  },
];

export const companySearchRoutes = [
  {
    path: `/companysearch`,
    redirect: `/companysearch/100`,
  },
  {
    path: `/companysearch/:resultsLoaded/:filterId?`,
    name: "SearchCompanies",
    components: {
      default: CompanySearchResultsView,
      map: () => mapView,
      filters: CompanySearchFilterBarCpt,
      header: HeaderCpt,
    },
    meta: {
      layout: "map",
      breadCrumb: true,
    },
    props: {
      header: () => ({ searchType: "companySearch" }),
    },
  },
  {
    path: `/t/companysearch`,
    redirect: `/t/companysearch/100`,
  },
  {
    path: `/t/companysearch/:resultsLoaded/:filterId?`,
    name: "TableSearchCompanies",
    components: {
      default: CompanySearchResultTableView,
      filters: CompanySearchFilterBarCpt,
      header: HeaderCpt,
    },
    meta: {
      layout: "table",
      breadCrumb: true,
    },
    props: {
      header: () => ({ searchType: "companySearch" }),
    },
  },
];
